import React from 'react';

const FILL = '#000';

const ShareIcon = (
  <svg width="18" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7 0a3.3 3.3 0 00-3 4.5L5.5 7.9A3.3 3.3 0 000 10.4a3.3 3.3 0
    005.5 2.4l6.2 3.4c-.2.4-.2.8-.2 1.3 0 1.8 1.4 3.2 3.2 3.2 1.8 0 3.3-1.4
    3.3-3.2a3.3 3.3 0 00-5.7-2.2L6.2 12a3.2 3.2 0 000-3l6-3.5A3.3 3.3 0 0018
    3.3C18 1.5 16.5 0 14.7 0z"
      fill={FILL}
    />
  </svg>
);

const FacebookIcon = (
  <svg width="11" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 .2v3.3H9c-.7 0-1.2.1-1.5.4-.2.3-.4.8-.4 1.4v2.4H11l-.5
    3.7H7V21H3.2v-9.6H0V7.7h3.2V4.9c0-1.5.5-2.7 1.4-3.6C5.5.4 6.6 0 8 0c1.2 0
    2.2 0 2.9.2z"
      fill={FILL}
    />
  </svg>
);

const TwitterIcon = (
  <svg width="19" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 1.8c-.7.3-1.4.5-2.2.6.8-.5 1.4-1.2 1.7-2-.8.4-1.6.7-2.4.8A3.9
    3.9 0 0013.2 0a3.8 3.8 0 00-3.8 4.6 11 11 0 01-8-4c-1 1.8-.6 4 1.1 5.1-.6
    0-1.2-.1-1.7-.4C.8 7 2 8.7 3.8 9c-.5.2-1.1.2-1.7.1a3.7 3.7 0 003.7 2.7A8.1
    8.1 0 010 13.3c1.7 1 3.8 1.7 6 1.7C13 15 17.2 9 17 3.7c.8-.5 1.4-1.2 2-2z"
      fill={FILL}
    />
  </svg>
);

const LinkedInIcon = (
  <svg width="19" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.3 5.8V18h-4V5.8h4zm.3-3.7A2 2 0 014 3.6c-.5.4-1 .6-1.7.6S1 4 .6
    3.6A2 2 0 010 2C0 1.5.2 1 .6.6c.5-.4 1-.6 1.7-.6s1.2.2 1.6.6c.5.4.7.9.7
    1.5zm14.4 9V18h-4v-6.5c0-.9-.2-1.5-.6-2-.3-.5-.8-.7-1.5-.7a2 2 0
    00-1.3.4c-.4.3-.7.6-.8 1l-.2 1V18h-4a1637 1637 0 000-11.6v-.6h4v1.8a5.3
    5.3 0 011.2-1.3l1.1-.5a5 5 0 011.4-.2c1.4 0 2.6.4 3.4 1.4.9.9 1.3 2.2 1.3 4z"
      fill={FILL}
    />
  </svg>
);

export {
  ShareIcon, FacebookIcon, TwitterIcon, LinkedInIcon,
};
