import React from 'react';
import { goToSection } from '../../utils';

import './explainer.scss';

const ExplainerSection = () => (
  <div className="section-inner section-explainer">
    <div className="receive-columns">
      <div className="receive-column --left">
        <h2>Meet ASAP PLZ!</h2>
        <p>
          Inspired by our favourite video games of the early 90s, it’s made for
          SEGA* Mega Drive and features the familiar three-button controller
          scheme.
        </p>
        <p>
          You can play it in your browser or download it on your computer.
          Don&apos;t forget to share it with your workmates!
        </p>
        <p className="bp-l-only">
          <button type="button" className="button" onClick={() => goToSection(2)}>
            Play now
          </button>
        </p>
      </div>
      <div className="receive-column --right">
        <img
          alt="Screenshot showing the game's title screen, similar to the boxart"
          className="explainer-screenshot"
          src="/images/screenshot-1.png"
        />
        <img
          alt="Screenshot showing a level in the game that contains a projector"
          className="explainer-screenshot"
          src="/images/screenshot-2.png"
        />
      </div>
    </div>
  </div>
);

export default ExplainerSection;
