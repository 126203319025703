import React, { useState } from 'react';

import {
  ShareIcon, TwitterIcon, FacebookIcon, LinkedInIcon,
} from '../icons';

import './share.scss';

const SHARE_COPY = 'ASAP PLZ takes you back to office politics and water cooler'
  + ' chats but in a 16-bit universe';

const WINDOW_FEATURES = 'menubar=no,location=no,resizable=yes,scrollbars=yes,'
  + 'status=no,width=600,height=800';

const ShareURL = {
  FACEBOOK: 'https://www.facebook.com/share.php?u=',
  TWITTER: `https://twitter.com/intent/tweet?hashtags=asapplz&text=${
    encodeURIComponent(SHARE_COPY)}&url=`,
  LINKEDIN: 'https://www.linkedin.com/sharing/share-offsite/?url=',
};

const SITE_URL = 'https://asapthegame.com';

const openShareWindow = (url: string, siteUrl: string) => {
  window.open(url + encodeURIComponent(siteUrl), 'SHARE', WINDOW_FEATURES);
};

const ShareBar = () => {
  const [isOpen, setOpen] = useState(false);
  const triggerOpen = () => setOpen(!isOpen);

  return (
    <div className="social-share">
      <button
        className={`share-button${isOpen ? ' is-active' : ''}`}
        onClick={triggerOpen}
        type="button"
        aria-label="Share"
      >
        { ShareIcon }
      </button>
      <ul className={`list${isOpen ? ' is-open' : ''}`}>
        <li>
          <a
            href={ShareURL.TWITTER + encodeURIComponent(SITE_URL)}
            onClick={(e) => {
              e.preventDefault();
              openShareWindow(ShareURL.TWITTER, SITE_URL);
            }}
          >
            Twitter
            { TwitterIcon }
          </a>
        </li>
        <li>
          <a
            href={ShareURL.FACEBOOK + encodeURIComponent(SITE_URL)}
            onClick={(e) => {
              e.preventDefault();
              openShareWindow(ShareURL.FACEBOOK, SITE_URL);
            }}
          >
            Facebook
            { FacebookIcon }
          </a>
        </li>
        <li>
          <a
            href={ShareURL.LINKEDIN + encodeURIComponent(SITE_URL)}
            onClick={(e) => {
              e.preventDefault();
              openShareWindow(ShareURL.LINKEDIN, SITE_URL);
            }}
          >
            LinkedIn
            { LinkedInIcon }
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ShareBar;
