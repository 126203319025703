import React, { useState } from 'react';
import startEmulator from './emulator';

import './emulator.scss';

interface EmulatorProps {
  url: string;
}

const Emulator: React.FC<EmulatorProps> = ({ url } : EmulatorProps) => {
  const [
    { isLoading, isReady },
    setState,
  ] = useState({ isLoading: false, isReady: false });
  const onClick = () => {
    startEmulator(url, () => setState({ isLoading: false, isReady: true }));
    setState({ isReady: false, isLoading: true });
  };
  return (
    <div className={`emulator-container${isReady ? ' is-ready' : ''}`}>
      <div className="canvas-wrapper">
        <div className="cta">
          <div
            className="emulator-loading"
            style={{ opacity: (isLoading ? '1' : '0') }}
          >
            {/* eslint-disable-next-line react/no-array-index-key */}
            { Array(7).fill(0).map((e, i) => <div className="letter" key={i} />) }
          </div>
        </div>
        { !isLoading && !isReady ? (
          <div className="cta">
            <button type="button" onClick={onClick} className="button">
              Launch game
            </button>
          </div>
        ) : ''}
        <canvas className="emulator-canvas" id="canvas" />
      </div>
    </div>
  );
};

export default Emulator;
