const EMU_GLOBAL_KEY = '__emuReady';
const GAME_FILE = 'asap_plz.bin';
const HOME_PATH = '/home/web_user';
const SCRIPT_PATH = '/scripts/Emulatrix_SegaGenesis.js';

export const EMU_WIDTH = 320;
export const EMU_HEIGHT = 224;

export const getConfig = () : string => {
  // Use scroll_lock for unwanted keybinds
  const emptyValue = 'scroll_lock';
  let config = '';

  const playerPrefix = 'input_player';
  const playerOne = `${playerPrefix}1_`;

  config += 'rgui_browser_directory = /\n';

  config += `${playerOne}start = enter\n`;

  config += `${playerOne}y = a\n`;
  config += `${playerOne}b = s\n`;
  config += `${playerOne}a = d\n`;

  config += `${playerOne}l = q\n`;
  config += `${playerOne}x = w\n`;
  config += `${playerOne}r = e\n`;

  const mainBindings = ['toggle_fast_forward', 'hold_fast_forward',
    'toggle_slowmotion', 'hold_slowmotion', 'save_state', 'load_state',
    'toggle_fullscreen', 'exit_emulator', 'state_slot_increase',
    'state_slot_decrease', 'rewind', 'movie_record_toggle', 'pause_toggle',
    'frame_advance', 'reset', 'shader_next', 'shader_prev', 'cheat_index_plus',
    'cheat_index_minus', 'cheat_toggle', 'screenshot', 'audio_mute',
    'osk_toggle', 'netplay_game_watch', 'enable_hotkey', 'volume_up',
    'volume_down', 'overlay_next', 'disk_eject_toggle', 'disk_next',
    'disk_prev', 'grab_mouse_toggle', 'game_focus_toggle', 'menu_toggle',
    'recording_toggle', 'streaming_toggle'];

  const playerBindings = ['up', 'down', 'left', 'right', 'start', 'select', 'a',
    'b', 'x', 'y', 'l', 'l2', 'l3', 'r', 'r2', 'r3', 'l_x_plus', 'l_x_minus',
    'l_y_plus', 'l_y_minus', 'r_x_plus', 'r_x_minus', 'r_y_plus', 'r_y_minus',
    'gun_trigger', 'gun_offscreen_shot', 'gun_aux_a', 'gun_aux_b', 'gun_aux_c',
    'gun_start', 'gun_select', 'gun_dpad_up', 'gun_dpad_down', 'gun_dpad_left',
    'gun_dpad_right', 'turbo'];

  mainBindings.forEach((binding) => {
    config += `input_${binding} = ${emptyValue}\n`;
  });

  for (let p = 1; p < 6; p += 1) {
    for (let i = 0; i < playerBindings.length; i += 1) {
      if (!(p === 1 && i <= 10)) {
        config += `${playerPrefix}${p}_${playerBindings[i]} = ${emptyValue}\n`;
      }
    }
  }

  config += 'video_vsync = true\n';
  config += 'video_scale = 1\n';
  config += `video_window_x = ${EMU_WIDTH}\n`;
  config += `video_window_y = ${EMU_HEIGHT}\n`;
  config += 'aspect_ratio_index = 23\n';
  config += `custom_viewport_width = ${EMU_WIDTH}\n`;
  config += `custom_viewport_height = ${EMU_HEIGHT}\n`;
  config += 'custom_viewport_x = 0\n';
  config += 'custom_viewport_y = 0\n';

  // Set high audio latency to improve performance
  config += 'audio_latency = 300\n';

  // Hide notifications
  config += 'video_message_pos_x = -100\n';
  config += 'video_message_pos_y = -100\n';
  config += 'menu_enable_widgets = false\n';

  return config;
};

function loadRomIntoVD(buffer: ArrayBuffer, onReady: () => void) {
  const windowRef: any = window;
  windowRef.startFileSystem();

  const dataView = new Uint8Array(buffer);
  windowRef.FS.createDataFile('/', GAME_FILE, dataView, true, false);

  windowRef.FS.createFolder(HOME_PATH, 'retroarch', true, true);
  windowRef.FS.createFolder(`${HOME_PATH}/retroarch`, 'userdata', true, true);

  const config = getConfig();

  windowRef.FS.createDataFile(
    `${HOME_PATH}/retroarch/userdata`,
    'retroarch.cfg',
    config,
    true,
    true,
  );

  // Load game after 1000ms
  setTimeout(() => {
    windowRef.Module.callMain(['-v', `/${GAME_FILE}`]);
    onReady();
  }, 1000);
}

async function loadRom(url: string, onReady: () => void) {
  const response = await fetch(url);
  loadRomIntoVD(await response.arrayBuffer(), onReady);
}

function startEmulator(url: string, onReady: () => void) {
  const windowRef: any = window;
  const loader = () => loadRom(url, onReady);
  // Create a global function that will get called from the embedded script
  windowRef[EMU_GLOBAL_KEY] = loader;

  // Embed the script file in the DOM
  const scriptNode = document.createElement('script');
  scriptNode.src = SCRIPT_PATH;
  document.body.appendChild(scriptNode);
}

export default startEmulator;
