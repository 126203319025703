import React, { useState } from 'react';
import SocialShare from '../../components/share';
import Form from '../../components/form';

import './receive.scss';

const option = {
  CARTRIDGE: 'cartridge',
  DOWNLOAD: 'download',
};

const DownloadSection = (
  <>
    <p>
      What you’ll download is called a ‘ROM’. You’ll need an emulator to run this.
      We recommend
      {' '}
      <a
        href="https://www.retroarch.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Retroarch
      </a>
      {' '}
      or
      {' '}
      <a href="https://openemu.org/" target="_blank" rel="noopener noreferrer">
        OpenEmu
      </a>
      .
    </p>
    <p className="receive-button-container">
      <a type="button" className="button --secondary" href="/roms/asap_plz.bin">
        <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.5 11.9V0H11v12L7 8.2l-2.6 2.6 8.2 8.2 8.3-8.3L18.3 8l-3.8 3.8z"
            fill="#fff"
          />
          <path
            d="M21.7 18.9v2.8h-18v-2.8H0v6.5h25.4v-6.5h-3.7z"
            fill="#fff"
          />
        </svg>
        Download
      </a>
    </p>
  </>
);

const CartridgeSection = (
  <>
    <p>
      Enter your email below to be the first to hear when this is ready to ship,
      bundled up in the iconic clamshell case with accompanying booklet.
    </p>
    <Form />
  </>
);

const ReceiveSection = () => {
  const [selection, setSelection] = useState(option.DOWNLOAD);

  return (
    <div className="section-inner receive-section">
      <div>
        <h2>Get the game</h2>
        <div className="receive-columns">
          <div className="receive-column --left">
            <div className="receive-selections">
              <div className="receive-option">
                <label htmlFor="downloadRadio">
                  <input
                    id="downloadRadio"
                    type="radio"
                    checked={selection === option.DOWNLOAD}
                    onChange={() => setSelection(option.DOWNLOAD)}
                  />
                  As a digital download
                </label>
              </div>
              <div className="receive-option">
                <label htmlFor="cartridgeRadio">
                  <input
                    id="cartridgeRadio"
                    type="radio"
                    checked={selection === option.CARTRIDGE}
                    onChange={() => setSelection(option.CARTRIDGE)}
                  />
                  On a Genesis / Mega Drive game cartridge
                </label>
              </div>
            </div>
            {selection === option.DOWNLOAD ? DownloadSection : CartridgeSection }
          </div>
          <div className="receive-column --right">
            <div className="receive-cartridge">
              <img src="/images/cartridge.png" alt="Game cartridge" />
            </div>
            <div className="receive-share">
              <SocialShare />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiveSection;
