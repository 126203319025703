import React from 'react';

import './logos.scss';

const Logos = () => (
  <div className="company-logos">
    <a href="https://yrstruly.uk/">
      <img src="/images/logo-yrstruly.svg" alt="YRS TRULY" className="yrstruly" />
    </a>
    <span className="seperator">✕</span>
    <a href="https://goodpraxis.coop">
      <img src="/images/logo-gp.svg" alt="Good Praxis" className="goodpraxis" />
    </a>
  </div>
);

export default Logos;
