import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Logos from '../components/logos';

import GameSection from '../sections/game';
import ExplainerSection from '../sections/explainer';
import HeroSection from '../sections/hero';
import ReceiveSection from '../sections/receive';

import './main.scss';

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Layout sections={[
      <HeroSection />,
      <ExplainerSection />,
      <GameSection />,
      <ReceiveSection />,
    ]}
    />
    <footer className="footer">
      <Logos />
      <div>
        <sup>*</sup>
        This game is NOT affiliated with or endorsed by SEGA. Brought to you by
        content powerhouse
        {' '}
        <a href="https://yrstruly.uk">YRS&nbsp;TRULY</a>
        {' '}
        and the digital heroes at
        {' '}
        <a href="https://goodpraxis.coop">Good&nbsp;Praxis</a>
        .
      </div>
    </footer>
  </>
);

export default IndexPage;
