import React, { useState } from 'react';

import './form.scss';

const URL = 'https://yrstruly.us4.list-manage.com/subscribe/post';
const USER = '460e9e6d3e3a9bf4d9de82697';
const ID = '6183d62b37';

const Form = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  return (
    <div id="mc_embed_signup" className="mailchimp-form">
      <form
        id="mc-embedded-subscribe-form"
        action={`${URL}?u=${USER}&amp;id=${ID}`}
        method="post"
        name="mc-embedded-subscribe-form"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group email pad-mid-top">
            <label htmlFor="mce-EMAIL">
              Your email address (required)
              {' '}
              <input
                id="mce-EMAIL"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="EMAIL"
              />
            </label>

          </div>
          <div className="mc-field-group name">
            <label htmlFor="mce-FNAME">
              Your name
              {' '}
              <input
                id="mce-FNAME"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="FNAME"
              />
            </label>

          </div>
          <div id="mergeRow-gdpr">
            <div>
              <p>
                Please select all the ways you would like to hear from YRS TRULY
                LTD:
              </p>
              <fieldset name="interestgroup_field">
                <label htmlFor="gdpr_25673">
                  <input
                    id="gdpr_25673"
                    type="checkbox"
                    name="gdpr[25673]"
                    value="Y"
                    className="av-checkbox"
                  />
                  <span> Email</span>
                </label>
              </fieldset>
              <div className="smallprint">
                You can unsubscribe at any time by clicking the link in the footer
                of our emails. For information about our privacy practices, please
                visit our website. We use Mailchimp as our marketing platform.
                By clicking below to subscribe, you acknowledge that your
                information will be transferred to Mailchimp for processing.
                Learn more about
                {' '}
                <a
                  href="https://mailchimp.com/legal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mailchimp&apos;s privacy practices
                </a>
                .
              </div>
            </div>
          </div>
          <div
            aria-hidden="true"
            style={{ position: 'absolute', left: '-5000px' }}
          >
            <input
              type="hidden"
              name="b_460e9e6d3e3a9bf4d9de82697_6183d62b37"
              tabIndex={-1}
              value=""
            />
          </div>
          <div>
            <input
              id="mc-embedded-subscribe"
              type="submit"
              value="Stay in the loop"
              name="subscribe"
              className="button --secondary"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
