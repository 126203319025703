import React from 'react';
import { goToSection } from '../../utils';
import Logos from '../../components/logos';

import './hero.scss';

const BOX_ALT = 'Game box artwork depicting a woman with red hair sitting at a'
  + ' desk, a man standing in the background with a teriffied facial expression'
  + ' while pieces of paper float around an office room.';

const HeroSection = () => (
  <>
    <div className="section-inner">
      <div className="column-half --flex --centered --reordered">
        <h2 className="hero-heading">
          Missing
          <br />
          office life?
        </h2>
        <p className="hero-paragraph">
          ASAP PLZ takes you back to office politics and water cooler chats but
          in a 16-bit universe.
        </p>
        <div className="cta">
          <button
            type="button"
            className="button hero-button bp-m-only"
            onClick={() => goToSection(1)}
          >
            View
          </button>
          <Logos />
        </div>
      </div>
      <div className="column-half --flex --tilted">
        <div className="game-box">
          <div className="ridge" />
          <div className="game-box-inside">
            <img
              className="game-box-inside-image"
              src="/images/box-inside.png"
              alt=""
            />
          </div>
          <div className="artwork-container">
            <img
              className="artwork"
              src="/images/boxart.jpg"
              alt={BOX_ALT}
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default HeroSection;
